$(document).ready(function() {


    $("#temperature-range").slider({
      range: true,
      min: 0,
      max: 15000,
      values: [ 3000, 12000 ],
      step: 100,
      slide: function( event, ui ) {
        $("#temperature-min").val( ui.values[ 0 ] + " K" );
        $("#temperature-max").val( ui.values[ 1 ] + " K" );
      }
    });
    $( "#temperature-min" ).val( $("#temperature-range").slider( "values", 0 ) + " K" );
    $( "#temperature-max" ).val( $("#temperature-range").slider( "values", 1 ) + " K" );

    $("#lumen-range" ).slider({
      range: true,
      min: 0,
      max: 50000,
      values: [ 10000, 40000 ],
      step: 100,
      slide: function( event, ui ) {
        $("#lumen-min" ).val( ui.values[ 0 ] );
        $("#lumen-max" ).val( ui.values[ 1 ] );
      }
    });
    $("#lumen-min").val($("#lumen-range").slider( "values", 0 ));
    $("#lumen-max").val($("#lumen-range").slider( "values", 1 ));


})